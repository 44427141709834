<template>
<div>
    <vs-checkbox
        class="text-xs float-left"
        v-model="isTermsConditionAcceptedChild"
        :value="isTermsConditionAcceptedChild"
        v-on:change="onCheck()"
    ></vs-checkbox>
    <span @click="displayTermAndConditionPopup()" >J'accepte les conditions générales <span class="cursor-pointer">(Afficher plus.. )</span></span>
</div>
</template>

<script>
export default {
  name: "termsConditionCheckbox",
  data () {
    return {
        isTermsConditionAcceptedChild: false,
    }
  },
  computed: {

  },
  methods: {
    onCheck() {
      this.$emit('termsConditionAcceptedEvent', this.isTermsConditionAcceptedChild)
    },
    displayTermAndConditionPopup() {
      this.$emit('termsConditionShowPopupEvent')
    }
  },
}
</script>
